import React from 'react';
import '../App.css';
import { ButtonLink } from './ButtonLink';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      {/* <video src='/videos/VID-20240114-WA0030.mp4' autoPlay loop muted /> */}
      <h1>FCS AWAITS</h1>
      <p>What are you waiting for? Why not you are calling us?</p>
      <p>A door-step service awiting you.</p>
      <div className='hero-btns'>
        <ButtonLink
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          target={'/email'}
          tabtype= {'email'}
        >
          <img alt="Contact US" src="../images/contact-us-icon.svg"/>
        </ButtonLink>
        <ButtonLink
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          style={{marginLeft: '510px' }}
          target={'https://wa.me/919235626411'}
          tabtype={'_blank'}
        >
        <img alt="Chat on WhatsApp" src="../images/WhatsAppButtonGreenSmall.svg"/>
      </ButtonLink>
      </div>
    </div>
  );
}

export default HeroSection;
