import { useParams } from 'react-router-dom';
import jsonData from './data/pricelist_itemwise.json';
import './ServiceDetails.css';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
const ServiceDetails = (props) => {
    //   const { slug } = useParams();
    // State to manage the canonical link value
    const [canonicalLink, setCanonicalLink] = useState('http://fcsonline.in/in-service/house-cleaning-services');

    // Effect to update the canonical link based on some logic or data
    useEffect(() => {
    // Replace this with your logic to determine the dynamic value
    const dynamicValue = determineDynamicValue();
    
    // Update the canonical link
    setCanonicalLink(`http://fcsonline.in/in-service/${dynamicValue}`);
    }, []); // Empty dependency array ensures the effect runs only once on mount

    // Function to simulate dynamic logic (replace with your actual logic)
    const determineDynamicValue = () => {
    // Replace this with your logic to determine the dynamic value
    return props.url_slug;
    };
  return (
    <div className='servicedetails__section'> 
        <Helmet>
            <title>{props.lbl}</title>
            <meta name="description" content={props.desc} />
            <link rel="canonical" href={canonicalLink} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content= {props.lbl}/>
            <meta property="og:description" content={props.desc} />
            <meta property="og:url" content={canonicalLink} />
            <meta property="og:site_name" content="fcsonline" />
            <meta name="og:image" content={props.img_src} />
        </Helmet>
        <h3>Fast Cleaning Services - {props.lbl}</h3>
        <p>{props.text}</p>
            <div className='servicedetails__container'>
                <div className='servicedetails__wrapper'>
                    <div className='servicedetails__item__text'>
                        <div className='servicedetails__item__tbl'>
                            <br/>
                            {props.data.map((item, index) => (
                                <>{item.itemname} <span class="badge">{item.price}</span><br/><br/>     
                                </>
                            ))}
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default ServiceDetails