import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import JumboSection  from '../jumbo';
import PricingSection from '../pricing';
import CallNowSection from '../Call_now';
import VideoFrame from '../VideoFrame';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <>
      <Helmet>
        <title>Cleaning Services, Home Cleaning, Sofa, Carpet Cleaning, water tank cleaning - FCS Online, Lucknow, U.P.</title>
        <meta name="description" content="we are providing best cleaning services such as sofa cleaning, water tank cleaning, home cleaning, office cleaning, flat cleaning and villa cleaning services, etc. from FCS Online" />
        <link rel="canonical" href="https://fcsonline.in/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cleaning Services, Home Deep Cleaning, Villa cleaning, balcony cleaing - FCS Online, Lucknow, U.P." />
        <meta property="og:description" content="we are providing best cleaning services such as sofa cleaning, water tank cleaning, home cleaning, office cleaning, flat cleaning and villa cleaning services, etc. from FCS Online" />
        <meta property="og:url" content="https://fcsonline.in/" />
        <meta property="og:site_name" content="fcsonline" />
        <meta property="og:image" content="https://fcsonline.in/images/office-chair-cleaning-image-with-white-background-upscaled.png" />
      </Helmet>

      <HeroSection />
      <JumboSection />
      <Cards />
      <PricingSection />
      <CallNowSection />
      <VideoFrame />
      <Footer />
    </>
  );
}

export default Home;
