// React Component with Validation and CSS

import React, { useState } from 'react';
import './EmailComponent.css'; // Import the CSS file
import emailjs from 'emailjs-com';

const EmailComponent = () => {
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  
  const [selectedValue, setSelectedValue] = useState('House Cleaning');
  const [errors, setErrors] = useState({});
  const [res_message, setResMessage] = useState("");


  const handleSendEmail = async (e) => {
    // Check for validation errors before sending the email
    if (validateForm()) {
      // setSubject(username + ' - '+ selectedValue);
      // e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

      try
      {
        const templateParams = {
          from_name: username,
          recipient_email: recipient,
          message: 'I need quote on '+ selectedValue + ' \n'+ message
        };
        // emailjs.init("'VdTVfj3HaX0-i0jLW");
        emailjs.send('service_3sadomb', 'template_4j0d8gi', templateParams, 'VdTVfj3HaX0-i0jLW')
        .then(function(response) {
          setResMessage("Thank you for your message. We have just received your email and will be responding shortly!");
          console.log('SUCCESS!', response.status, response.text);
       }, function(error) {
        setResMessage("Email send failed!");
        console.log('FAILED...', error);
       });
  

      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  };

  const validateForm = () => {
    const errorsObj = {};
    // Email validation regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check for blank fields and update the errors object
   // Check for blank recipient email
      if (recipient.trim() === '') {
        errorsObj.recipient = 'Recipient email is required';
      } else if (!emailRegex.test(recipient)) {
        errorsObj.recipient = 'Invalid email format';
      }

    // if (subject.trim() === '') {
    //   errorsObj.subject = 'Subject is required';
    // }
    if (username.trim() === '') {
      errorsObj.username = 'Username  required';
    }

    // if (message.trim() === '') {
    //   errorsObj.message = 'Message is required';
    // }

    setErrors(errorsObj);

    // Return true if there are no errors, otherwise false
    return Object.keys(errorsObj).length === 0;
  };

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <div className="email-container">
      <div className='callnow__item__text'>
          <p>Get a free Quote and free Visit? Feel free to connect with us.</p>
          <p className='quotedesc'>We will delight to help you on all cleaning services in all area of Lucknow City, U.P.</p>
      </div>

     <div className="input-section">
        <label>Recipient Email:</label>
        <input
          type="email"
          placeholder="Recipient Email"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          className={`input-field ${errors.recipient ? 'error' : ''}`}
        />
        {errors.recipient && <span className="error-message">{errors.recipient}</span>}
      </div>

      {/* <div className="input-section" style={{display: 'none'}}>
        <label>Subject:</label>
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className={`input-field ${errors.subject ? 'error' : ''}`}
        />
        {errors.subject && <span className="error-message">{errors.subject}</span>}
      </div> */}
      <div className="input-section">
        <label>Name:</label>
      <input
        type="text"
          placeholder="You Name"
                value={username}
        onChange={(e) => setUsername(e.target.value)}
        className={`input-field ${errors.subject ? 'error' : ''}`}
        />
          {errors.username && <span className="error-message">{errors.username}</span>}
     </div>
      <div className="input-section">
        <label>Message:</label>
        <textarea
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className={`textarea-field ${errors.message ? 'error' : ''}`}
        />
        {/* {errors.message && <span className="error-message">{errors.message}</span>} */}
      </div>

      <div className="input-section">
        <label>Select Services:</label>
        <select value={selectedValue} onChange={handleDropdownChange} className="dropdown-field">
        <option value="Home Cleaning">Home Cleaning</option>
          <option value="House Cleaning">House Cleaning</option>
          <option value="Bathroom Cleaning">Bathroom Cleaning</option>
          <option value="Office Cleaning">Office Cleaning</option>
          <option value="Sofa Cleaning">Sofa Cleaning</option>
          <option value="Carpet Cleaning">Carpet Cleaning</option>
          <option value="Kitchen Cleaning">Kitchen Cleaning</option>
          <option value="Refrigrator Cleaning">Refrigrator Cleaning</option>
          <option value="WaterTank Cleaning">WaterTank Cleaning</option>
          <option value="Villa Cleaning">Villa Cleaning</option>
          <option value="Flat Cleaning">Flat Cleaning</option>

        </select>
      </div>
      <button onClick={handleSendEmail} className="send-button">
        Send Email
      </button>
      <br/>
      <br/>
      <p>{res_message}</p>

    </div>
  );
};

export default EmailComponent;
