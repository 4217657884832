import React from 'react'
import './jumbo.css'
import { FcBusinessman , FcReuse,  FcAlarmClock   } from "react-icons/fc";

const jumbo = () => {
  return (
    <>
    <div className='jumbosec'>
    
    <h1>FCS (Fast Cleaning Services) - Professional Cleaning Services Company</h1>
    <p>FCS is a prominent facility management company specializing in cleaning services, office in Lucknow, U.P. With a wealth of experience in the cleaning industry, we have established ourselves as a distinguished entity in the field. Our extensive range of professional cleaning solutions caters to both residential and commercial spaces.
        Choose FCS for dependable and high-quality cleaning services in your home. We guarantee world-className cleaning, including but not limited to house cleaning services, office cleaning, floor cleaning and commercial cleaning services.
        At FCS, we take pride in delivering reliable and top-notch cleaning solutions to meet your diverse needs.
    </p>
    <br/>
    <p>
        FCS specializes in delivering comprehensive cleaning and dry cleaning services on a daily, weekly, and monthly basis. Our commitment is to create a clean, dirt-free, and toxin-free environment, both within and outside residential and office spaces. This dedication allows our clients to concentrate on their core business activities and enhance overall performance.
        We prioritize the cleanliness and well-being of your surroundings, ensuring a conducive atmosphere that promotes productivity. By entrusting your cleaning needs to FCS, you are investing in a service that goes beyond routine maintenance to foster a hygienic and optimal working or living environment.
        </p>{/* <ul>     
            <li>Experienced as well as customer-friendly technicians.</li>
            <li>During the cleaning process, we maintain hygiene by using all advanced technologies.</li>
            <li>We take care of dust removal and try to minimize disease or infection which causes due to unhygienic conditions in the residential, office & other areas.</li>
            <li>We are ready to serve at any time</li>
            <li>Flexible booking time slots</li>
        </ul></p> */}
        <div className="standing-cards">
    <ol className="olcards">
		<li style={{"--cardColor":"#fc374e"}}>
			<div className="content">
				<div className="icon"></div>
				<div className="title"><FcBusinessman /> EXPERIENCED</div>
				<div className="text">We are higly exprienced in cleaning services and we also have very customer-friendly technicians.</div>
			</div>
		</li>
		<li style={{"--cardColor":"#36aeb3"}}>
			<div className="content">
				{/* <div className="icon"></div> */}
				<div className="title"><FcReuse /> HYGIENIC</div>
				<div className="text">We maintain hygiene by using all advanced technologies andand try to minimize disease or infection which causes due to unhygienic conditions in the residential & other areas.</div>
			</div>
		</li>
    <li style={{"--cardColor":"#bb5b5b76"}}>
			<div className="content">
				{/* <div className="icon"></div> */}
				<div className="title"><FcAlarmClock/> FLEXIBLE</div>
				<div className="text">We are ready to serve at any time and flexible booking time slots</div>
			</div>
		</li>
	</ol>
    </div>

    
    </div>
    </>
  )
}

export default jumbo
