import React from 'react'
import './pricing.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ButtonLink } from './ButtonLink'
import {BsArrowDownSquareFill } from 'react-icons/bs'
import {GiCrystalize} from 'react-icons/gi'
import {IconContext} from 'react-icons/lib'
import { BsFillHousesFill, BsFillHouseUpFill, BsHospitalFill  } from "react-icons/bs";

const pricing = () => {
  return (
    <IconContext.Provider value={{color: '#fff', size: 64}}>
    <div>
      <div className='pricing_section'>
        <div className='pricing_wrapper'>
            <h1 className='pricing_heading'>Pricing</h1>
            <div className='pricing_container'>
                <Link to="/pricelist" className="pricing_container-card">
                 <div className='pricing_container-cardInfo'>
                    <div className='icon'>
                    <BsFillHousesFill />
                    </div>
                    <h3>Home Cleaning</h3>
                    <h4><span class="price-symbol">₹</span>8500</h4>
                    <p>*Onwards</p>
                    <ul className='pricing_container-features'>
                        <li>Bathroom and Kitchen Cleaning</li>
                        <li>Rooms and Refrigrator Cleaning</li>
                        <li>Bolcony Cleaning</li>
                    </ul>
                    <ButtonLink buttonSize='btn--wide' buttonColor='primary'>
                    Book Now &gt;
                    </ButtonLink>
                    <p>* Price can change based on final eastimation</p>
                 </div>
                </Link>

                <Link to="/pricelist" className="pricing_container-card">
                 <div className='pricing_container-cardInfo'>
                    <div className='icon'>
                    <BsFillHouseUpFill />
                    </div>
                    <h3>Villa Cleaning</h3>
                    <h4><span class="price-symbol">₹</span>11,499</h4>
                    <p>*Onwards</p>
                    <p>2000-3000 Sq. ft.</p>
                    <ul className='pricing_container-features'>
                        <li>Bolcony, Gardeen and Looby</li>
                        <li>Rooms, Kitchen and Refrigrator Cleaning</li>
                    </ul>
                    <ButtonLink buttonSize='btn--wide' buttonColor='primary'>
                        Book Now &gt;
                    </ButtonLink>
                    <p>* Price can change based on final eastimation</p>
                 </div>
                </Link>

                <Link to="/pricelist" className="pricing_container-card">
                 <div className='pricing_container-cardInfo'>
                    <div className='icon'>
                        <BsHospitalFill  />
                    </div>
                    <h3>Office Cleaning</h3>
                    <h4><span class="price-symbol">₹</span>4000</h4>
                    <p>*Onwards</p>
                    <ul className='pricing_container-features'>
                        <li>Office Front</li>
                        <li>Confrence Room</li>
                    </ul>
                    <ButtonLink buttonSize='btn--wide' buttonColor='primary'>
                        Book Now &gt;
                    </ButtonLink>
                    <p>* Price can be changed based on Sq. ft. changed.</p>
                 </div>
                </Link>
            </div>
        </div>
      </div>
    </div>
    </IconContext.Provider>
  )
}

export default pricing
