import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import CallNowSection from '../Call_now';
import Services_header from '../Services_header';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
      <Helmet>
        <title>Cleaning Services, Home Cleaning, Sofa, Carpet Cleaning, water tank cleaning - FCS Online, Lucknow, U.P.</title>
        <meta name="description" content="we are providing best cleaning services such as sofa cleaning, water tank cleaning, home cleaning, office cleaning, flat cleaning and villa cleaning services, etc. from FCS Online" />
        <link rel="canonical" href="https://fcsonline.in/about" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cleaning Services, Home Deep Cleaning, Villa cleaning, balcony cleaing - FCS Online, Lucknow, U.P." />
        <meta property="og:description" content="we are providing best cleaning services such as sofa cleaning, water tank cleaning, home cleaning, office cleaning, flat cleaning and villa cleaning services, etc. from FCS Online" />
        <meta property="og:url" content="https://fcsonline.in/about" />
        <meta property="og:site_name" content="fcsonline" />
        <meta property="og:image" content="https://fcsonline.in/images/aboutus_cleaningimage.jpg" />
      </Helmet>


      <Services_header head_title='DYNAMIC AND PROFESSIONAL CLEANING COMPANY IN LUCKNOW, U.P.'
          imageLinks='/images/aboutus_cleaningimage.jpg'/>

      <div className='aboutus'> 
        <h2>ABOUT US</h2>
        <p>FCS (Fast Cleaning Services) - Professional Cleaning Services Company
        FCS is a prominent facility management company specializing in cleaning services, office in Lucknow, U.P. With a wealth of experience in the cleaning industry, we have established ourselves as a distinguished entity in the field.<br/><br/>Our extensive range of professional cleaning solutions caters to both residential and commercial spaces. Choose FCS for dependable and high-quality cleaning services in your home.<br/><br/>We guarantee world-class cleaning, including but not limited to house cleaning services, office cleaning, floor cleaning and commercial cleaning services. At FCS, we take pride in delivering reliable and top-notch cleaning solutions to meet your diverse needs.

        <br/><br/>
        FCS specializes in delivering comprehensive cleaning and dry cleaning services on a daily, weekly, and monthly basis.<br/><br/>Our commitment is to create a clean, dirt-free, and toxin-free environment, both within and outside residential and office spaces. This dedication allows our clients to concentrate on their core business activities and enhance overall performance.<br/><br/>We prioritize the cleanliness and well-being of your surroundings, ensuring a conducive atmosphere that promotes productivity.<br/><br/>By entrusting your cleaning needs to FCS, you are investing in a service that goes beyond routine maintenance to foster a hygienic and optimal working or living environment.
        Experienced as well as customer-friendly technicians.<br/><br/>
        During the cleaning process, we maintain hygiene by using all advanced technologies.
        We take care of dust removal and try to minimize disease or infection which causes due to unhygienic conditions in the residential, office & other areas.
        We are ready to serve at any time
        Flexible booking time slots</p>
        <div style={{ textAlign: 'center' }}>
          <h3>Call or Meet us for all cleaning solutions</h3>
          <p style={{ color: 'blue', fontSize: '18px', fontWeight: 'bold' }}>
            Address: B-32, Eldeco HighwayPlaza, Dental College Rd. op. S.P., Lucknow - 226029
          </p>
          <p style={{ color: 'green', fontSize: '16px' }}>
            You can call us - +919794324845
          </p>
          <p>What's up us - <b>9235626411</b></p>
          <p style={{ color: 'purple', fontSize: '16px' }}>
            Please email us - help_fcs@fcsonline.in
          </p>
        </div>
      </div>

      <CallNowSection />
      <Footer />
    </>
  );
}

export default About;
