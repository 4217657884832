import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';
import CallNowSection from '../Call_now';
import Services_header from '../Services_header';
function Services() {
  return (
    <>
      <Services_header head_title='Fast Cleaning Services'
          imageLinks='/images/img-home.jpg'/>
          
      <Cards />
      <CallNowSection />
      <Footer />
    </>
  );
}

export default Services;
