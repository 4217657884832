import React from 'react'
import './Services_header.css'
const Services_header = (props) => {
  // const { head_title } = props;
  return (
    <>
        <div className='Service__wrapper'>
            <div className='service-container'
              style={{
                backgroundImage: `url(${props.imageLinks})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat', 
              }}
            >
                <h1>{props.head_title}</h1>
            </div>
        </div>
    </>
  )
}
export default Services_header