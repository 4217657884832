import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css'
import email from './EmailComponent'
const STYLES = ['btn--primary','btn--warning', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

export const ButtonLink = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  target,
  tabtype
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <>
      {tabtype === 'email' ? (
        <Link to='/email' className='btn-mobile'>
          <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
          >
            {children}
          </button>
        </Link>
      ) : (
        <a href={`${target}`} className='btn-mobile' target={`${tabtype}`} rel="noopener">
          <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
          >
            {children}
          </button>
        </a>
      )}
    </>
  );
};
