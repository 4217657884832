import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Services_header from '../Services_header';
import ServiceDetails from '../ServiceDetails';
import CallNow from '../Call_now';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import jsonData from '../data/pricelist_itemwise.json'


function Service_Provider(props) {
  const { slug } = useParams();
  const [priceList, setpriceList] = useState([]);
  const [lbl, setlbl] = useState();
  const [url_slug, seturl_slug] = useState();
  const [img_src, setimg_src] = useState();
  const [desc, setdesc] = useState();

//   const { productdetailProps } = props.location;
//   setData(productdetailProps)
//  console.log(slug )
  const getDataByFilter = (key, value) => {
    // Use the filter method to get items that match the key-value pair
    const filteredData = jsonData.filter(item => item[key] === value);
    return filteredData;
  };

  // Example: Get items with 'url_slug' equal to slug
  const filteredItems = getDataByFilter('url_slug', slug);
  console.log(filteredItems)

  // filteredItems.map(item => (
  //       <>{setpriceList(item.list_items)}
  //         {setlbl(item.lbl)}
  //         {seturl_slug(item.url_slug)}
  //         {setimg_src(item.img_src)}
  //         {setdesc(item.desc)}
  //         </>
  //   ));
  return (
    <>
      <Services_header head_title={filteredItems[0].lbl}
          imageLinks={filteredItems[0].img_src}/>
    
      <ServiceDetails data={filteredItems[0].list_items} lbl={filteredItems[0].lbl} url_slug={filteredItems[0].url_slug} 
      img_src={filteredItems[0].img_src} text={filteredItems[0].text} desc={filteredItems[0].desc}
      />
      <CallNow />
      <Footer />
    </>
  );
}

export default Service_Provider;
