import React from 'react'
import './PriceLists.css'
import jsonData from './data/items_wise_pricelist.json'

const Price = () => {
  return (
    <div className="app">
        <div className="content-container">
         <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Below is price list based on cleaning type</h3>
                <div className="table-container">
                    <table>
                    <thead>
                        <tr>
                        <th>Cleaning Type</th>
                        <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr><td></td></tr>
                    {jsonData.map((item, index) => (
                      <tr key={index}>
                        {item.note === "" ? <><td>{item.item_name}</td> <td><b><span className="price-symbol">₹</span></b>{item.item_price}</td></>
                                   : <><td><span style={{color: 'red'}}>*</span> {item.item_name} <span className="note"><div dangerouslySetInnerHTML={{ __html: item.note }} /></span></td><td><b><span className="price-symbol">₹</span></b>{item.item_price}</td></>}
                                   
                      </tr>
                    ))}
                    </tbody>
                    </table>
            </div>
        </div>
  </div>
  )
}

export default Price
