import React,  { useState } from 'react'
import './Videoframe.css'

const VideoFrame = () => {
 return (

  <div className="video-container">
     <video src="./videos/VID-20240126-WA0015.mp4" autoPlay muted controls className="video" />
  </div>
    /* <div className='videosec'>
      <iframe src="./videos/VID-20240114-WA0030.mp4" width={300} height={200} ></iframe>

    </div> */
  );
};
export default VideoFrame;