import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
    
      <li className='cards__item'>
        {
        
        /* this function is commented because there is no use for now- <Link className='cards__item__link' to={{ pathname: `/in-service/${props.url_slug}`, productdetailProps: 
          {
            'img_src': props.src,
            'lbl': props.label,
            'desc': props.desc,
            'txt': props.text
          } }}> */}
          <Link className='cards__item__link' to={{ pathname: `/in-service/${props.url_slug}`}}> 
                  <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt={props.desc} 
              src={props.src}
              
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
