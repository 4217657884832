import React, { useState } from 'react';
import './WhatsAppButton.css';
import { Link } from 'react-router-dom';

const WhatsAppButton = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    console.log('clicked');
    // setPopupVisible(!popupVisible);
  };

  return (
    <div className="whatsapp-container">
      <a href='https://wa.me/919235626411' target='_blank'>
        <div className="whatsapp-button">
          <i class="fab fa-whatsapp"></i>
        </div>
      </a>
      {popupVisible && (
        <div className="whatsapp-popup">
          <div className="whatsapp-popup-content">
            <p>Your message or contact details go here.</p>
          </div>
          <div className="whatsapp-close" onClick={togglePopup}>
            &times;
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppButton;
