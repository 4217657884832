import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import fakeData from './data/service_cards.json';
import WhatsAppBtn from './WhatsAppButton';

function Cards() {
  return (
    <div className='cards'>
      <h1>Our Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <WhatsAppBtn />
          <ul className='cards__items'>
            <CardItem
              src= {fakeData[0]["img_src"]}
              text={fakeData[0]["text"]}
              label= {fakeData[0]["label"]}
              desc = {fakeData[0]["desc"]}
              url_slug = {fakeData[0]["url_slug"]}
              path={fakeData[0]["redirect_path"]}
            />
            <CardItem
              src= {fakeData[10]["img_src"]}
              text={fakeData[10]["text"]}
              label= {fakeData[10]["label"]}
              desc = {fakeData[10]["desc"]}
              url_slug = {fakeData[10]["url_slug"]}
              path={fakeData[10]["redirect_path"]}
            />
            <CardItem
              src= {fakeData[1]["img_src"]}
              text={fakeData[1]["text"]}
              label= {fakeData[1]["label"]}
              desc = {fakeData[1]["desc"]}
              url_slug = {fakeData[1]["url_slug"]}
              path={fakeData[1]["redirect_path"]}
            />
            <CardItem
              src= {fakeData[2]["img_src"]}
              text={fakeData[2]["text"]}
              label= {fakeData[2]["label"]}
              desc = {fakeData[2]["desc"]}
              url_slug = {fakeData[2]["url_slug"]}
              path={fakeData[2]["redirect_path"]}
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src= {fakeData[3]["img_src"]}
              text={fakeData[3]["text"]}
              label= {fakeData[3]["label"]}
              desc = {fakeData[3]["desc"]}
              url_slug = {fakeData[3]["url_slug"]}
              path={fakeData[3]["redirect_path"]}
            />
          <CardItem
              src= {fakeData[4]["img_src"]}
              text={fakeData[4]["text"]}
              label= {fakeData[4]["label"]}
              desc = {fakeData[4]["desc"]}
              url_slug = {fakeData[4]["url_slug"]}
              path={fakeData[4]["redirect_path"]}
            />
          <CardItem
              src= {fakeData[5]["img_src"]}
              text={fakeData[5]["text"]}
              label= {fakeData[5]["label"]}
              desc = {fakeData[5]["desc"]}
              url_slug = {fakeData[5]["url_slug"]}
              path={fakeData[5]["redirect_path"]}
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src= {fakeData[6]["img_src"]}
              text={fakeData[6]["text"]}
              label= {fakeData[6]["label"]}
              desc = {fakeData[6]["desc"]}
              url_slug = {fakeData[6]["url_slug"]}
              path={fakeData[6]["redirect_path"]}
            />
          <CardItem
              src= {fakeData[7]["img_src"]}
              text={fakeData[7]["text"]}
              label= {fakeData[7]["label"]}
              desc = {fakeData[7]["desc"]}
              url_slug = {fakeData[7]["url_slug"]}
              path={fakeData[7]["redirect_path"]}
            />
          <CardItem
              src= {fakeData[8]["img_src"]}
              text={fakeData[8]["text"]}
              label= {fakeData[8]["label"]}
              desc = {fakeData[8]["desc"]}
              url_slug = {fakeData[8]["url_slug"]}
              path={fakeData[8]["redirect_path"]}
            />
          <CardItem
              src= {fakeData[9]["img_src"]}
              text={fakeData[9]["text"]}
              label= {fakeData[9]["label"]}
              desc = {fakeData[9]["desc"]}
              url_slug = {fakeData[9]["url_slug"]}
              path={fakeData[9]["redirect_path"]}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

