import React, { useState } from 'react';
import './Call_now.css'
import emailjs from 'emailjs-com';

const Callnow = () => {
  const [username, setUsername] = useState('');
  const [recipient, setRecipient] = useState('');
  const [message, setMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState('House Cleaning');
  const [res_message, setResMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleSendEmail = async () => {
    if (validateForm()) {
      try
      {
        const templateParams = {
          from_name: username,
          recipient_email: recipient,
          message: 'I need quote on '+ selectedValue + ' \n'+ message
        };
        // emailjs.init("'VdTVfj3HaX0-i0jLW");
        emailjs.send('service_3sadomb', 'template_4j0d8gi', templateParams, 'VdTVfj3HaX0-i0jLW')
        .then(function(response) {
          setResMessage("Thank you for your message. We have just received your email and will be responding shortly!");
          console.log('SUCCESS!', response.status, response.text);
       }, function(error) {
        setResMessage("Email send failed! Sorry for inconvenience please retry.");
        console.log('FAILED...', error);
       });
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  };
  const validateForm = () => {
    const errorsObj = {};
    // Email validation regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check for blank fields and update the errors object
   // Check for blank recipient email
      if (recipient.trim() === '') {
        errorsObj.recipient = 'Recipient email is required';
      } else if (!emailRegex.test(recipient)) {
        errorsObj.recipient = 'Invalid email format';
      }

    // if (subject.trim() === '') {
    //   errorsObj.subject = 'Subject is required';
    // }
    if (username.trim() === '') {
      errorsObj.username = 'Username  required';
    }

    if (message.trim() === '') {
      errorsObj.message = 'Message is required';
    }

    setErrors(errorsObj);

    // Return true if there are no errors, otherwise false
    return Object.keys(errorsObj).length === 0;
  };
  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };
  return (
    <>
    <div className='call__now'>
      <div className='callnow__container'>
        <div className='callnow__wrapper'>
          <div className='callnow__item__text'>
            <p>Get a free Quote and free Visit? Feel free to connect with us.</p>
            <p className='quotedesc'>We will delight to help you on all cleaning services in all area of Lucknow City, U.P.</p>
            <p style={{ color: 'blue', fontSize: '18px', fontWeight: 'bold' }}>
            Address: 12B/584, Vrindavan Yojana, Sec - 12, Telibagh, Lucknow - 226029
          </p>
          <p style={{ color: 'green', fontSize: '16px' }}>
            You can call us - +919794324845
          </p>
          <p style={{ color: 'purple', fontSize: '16px' }}>
            Please email us - help_fcs@fcsonline.in
          </p>

          </div>
          <div className="contact-form">
            <div className="form-container">
                <div className='form'>
                  {/* <form> */}
                      <div className="form-group">
                          <label for="name">Your Name:</label>
                                <input type="text" id="name" name="name"
                                  value={username}
                                  onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your name" required/>
                            {errors.username && <span className="error-message">{errors.username}</span>}
                        </div>
                        <div className="form-group">
                              <label for="email">Your Email:</label>
                              <input type="email" id="email"
                                value={recipient}
                                onChange={(e) => setRecipient(e.target.value)}
                                name="email" placeholder="Enter your email" required/>
                            {errors.recipient && <span className="error-message">{errors.recipient}</span>}
                        </div>
                        <div className="form-group">
                              <label for="message">Your Message:</label>
                              <textarea id="message" name="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Enter your message" rows="4" required></textarea>
                            {errors.message && <span className="error-message">{errors.message}</span>}
                        </div>
                        <div className="form-group">
                          <label>Select Services:</label>
                          <select value={selectedValue} onChange={handleDropdownChange} className="dropdown-field">
                            <option value="Home Cleaning">Home Cleaning</option>
                            <option value="House Cleaning">House Cleaning</option>
                            <option value="Bathroom Cleaning">Bathroom Cleaning</option>
                            <option value="Office Cleaning">Office Cleaning</option>
                            <option value="Sofa Cleaning">Sofa Cleaning</option>
                            <option value="Carpet Cleaning">Carpet Cleaning</option>
                            <option value="Kitchen Cleaning">Kitchen Cleaning</option>
                            <option value="Refrigrator Cleaning">Refrigrator Cleaning</option>
                            <option value="WaterTank Cleaning">WaterTank Cleaning</option>
                            <option value="Villa Cleaning">Villa Cleaning</option>
                            <option value="Flat Cleaning">Flat Cleaning</option>

                          </select>
                          <button onClick={handleSendEmail} className="btn--wide primary">Submit</button>
                        </div>
                        {/* </form> */}
                      </div>
                    
                </div>
                <div className='errormessage'>{res_message}</div>
              </div>
          </div>
      </div>
    </div>
  </>
  )
}

export default Callnow
