import React from 'react';
import './Footer.css';
import { ButtonLink } from './ButtonLink';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Call or Meet us for all cleaning solutions
        </p>
        <p><b>Address:</b> B-32, Eldeco HighwayPlaza, Dental College Rd. op. S.P., Lucknow - 226029</p>
        <p>You can call us - <b>+919794324845</b></p>
        <a aria-label="Chat on WhatsApp" target='_blank' href="https://wa.me/919235626411"> <img alt="Chat on WhatsApp" src="../images/WhatsAppButtonGreenSmall.svg"/></a>
        <p>Please email us - <a style={{color: 'red'}} href='help_fcs@fcsonline.in'>help_fcs@fcsonline.in</a></p><br/>
        <ButtonLink
          className='btns'n
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >CONTACT US</ButtonLink>
        <div className='input-areas'>
          {/* <form>
          <input
              className='footer-input'
              name='name'
              type='name'
              placeholder='Your Name'
            />
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <input
              className='footer-input-desc'
              name='desc'
              type='desc'
              placeholder='Description'
            />
            <ButtonLink buttonStyle='btn--outline'>Send Email</ButtonLink>
          </form> */}
          <br/>
          <h3>ABOUT</h3>
          <p>We are known for our excellent facility maintenance services, as well as our knowledge, expertise, and ability to share and implement the best cleaning and hygiene practices.</p>
          <p>We follow systematic planning and procedures in providing our services.</p>
        </div>
      </section>
      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Our Video</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src='../images/logo.jpg'  width='120px' height='50px'/>
            </Link>
          </div>
          <small class='website-rights'>FCS © 2024</small>
          <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href='https://www.instagram.com/p/C3Iu8-evn14/?igsh=MWg2djA5eXA1aWUwMg=='
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/p/C3Iu8-evn14/?igsh=MWg2djA5eXA1aWUwMg=='
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link youtube'
              href='https://youtube.com/@FASTCLEANINGSERVICES?si=YVjmsUuCAWGQgE4Y'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </a>
            {/* <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
