import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import Services from './components/pages/Services';
import About from './components/pages/About'
import inservice from './components/pages/Service_Provider'
import Email from './components/EmailComponent'
import PriceList from './components/pages/PriceSection';
import ContactUs from './components/pages/Contact';
import ErrorPage from './components/pages/ErrorPage';

function App() {
  return (
    <>
      <Router>
        <Navbar />
          <Route path='/' exact component={Home}  />
          <Route path='/services' component={Services} />
          <Route path='/about' component={About}/>
          <Route path='/in-service/:slug' component={inservice} />
          <Route path='/email' component={Email} />
          <Route path='/pricelist' component={PriceList} />
          <Route path='/contact' component={ContactUs} />
          <Redirect from='/' to='/'/>
          {/* <Route path="*" element={ErrorPage} /> */}
          {/* <Route path="/users/:slug" component={UserDetail} /> */}

          
      </Router>
    </>
  );
}

export default App;
